"use client";

import { Fragment, useState } from "react";
import Link from "next/link";
import { GoCoreLogo } from "../logo";
import UkFlag from "@public/icons/flags/uk-flag.svg";
import Menu from "@public/icons/menu.svg";
import Close from "@public/icons/close.svg";
import RuFlag from "@public/icons/flags/ru-flag.svg";
import Magnifier from "@public/icons/magnifier.svg";
import DotaIcon from "@public/icons/games/dota.svg";
import LolIcon from "@public/icons/games/lol.svg";
import CSIcon from "@public/icons/games/cs.svg";
import TwitterIcon from "@public/icons/socials/twitter.svg";
import YoutubeIcon from "@public/icons/socials/youtube.svg";
import InstagramIcon from "@public/icons/socials/instagram.svg";
import TikTokIcon from "@public/icons/socials/tiktok.svg";
import { usePathname } from "next/navigation";
import clsx from "clsx";
import Image from "next/image";
import { DialogPanel, Popover, PopoverButton, PopoverPanel, TransitionChild } from "@headlessui/react";
import { nanoid } from "nanoid";
import { GameFilter, useMyContext } from "../context";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { Dialog, Transition, DialogBackdrop, DialogTitle } from "@headlessui/react";
import useToggle from "@/hooks/useToggle";
import RegisterForm from "../forms/register";
import LoginForm from "../forms/login";
import { XMarkIcon } from "@heroicons/react/24/solid";
import Button from "../button";
import usePlayer from "@/hooks/usePlayer";
import Loader from "../loader";

interface IHeaderProps { }
type LanguageType = "eng" | "ru";

interface SidebarProps {
  isOpen: boolean;
  children: React.ReactNode;
  className?: string;
}
const Sidebar = ({ isOpen, children: children, className }: SidebarProps) => {
  return (
    <div
      className={clsx(
        "h-screen bg-gray-800 text-white fixed top-0 left-0 transform transition-transform duration-300 ease-in-out bg-medium-gray p-4",
        {
          "-translate-x-full": !isOpen,
          "translate-x-0": isOpen,
        },
        className
      )}
    >
      {children}
    </div>
  );
};

const Header = (props: IHeaderProps) => {

  const [mobileMenuOpen, toggleMobileMenu] = useToggle()
  const [registerDialogOpen, toggleRegisterDialog] = useToggle()
  const [loginDialogOpen, toggleLoginDialog] = useToggle()


  const pathname = usePathname();
  const { value, setValue } = useMyContext();

  const { data: player, isLoading: isPlayerLoading } = usePlayer()

  const games = [
    { label: "All", name: "ALL", icon: <></>, href: "#" },
    {
      name: "DOTA",
      label: "Dota 2",
      icon: (
        <DotaIcon
          className={clsx(value === "DOTA" ? "text-red" : "text-white")}
        />
      ),
      href: "#",
    },
    {
      name: "CS",
      label: "CS 2",
      icon: (
        <CSIcon
          className={clsx(value === "CS" ? "text-orange" : "text-white")}
        />
      ),
      href: "#",
    },
  ];
  const navigation = [
    { name: "Home", href: "/home" },
    { name: "News", href: "/news" },
    { name: "Matches", href: "/matches" },
    { name: "Tips", href: "/tips" },
    { name: "Pick'ems", href: "/pickems" },
  ];
  const solutions = [
    {
      name: "Bookmakers",
      href: "/hub",
    },
    {
      name: "Bonuses",
      href: "/hub/bonuses",
    },
  ];

  const mobileNavigation = [
    { name: "Home", href: "/home" },
    { name: "News", href: "/news" },
    { name: "Matches", href: "/matches" },
    {
      name: "Betting HUB",
      subLinks: [
        { name: "Bookmakers", href: "/hub" },
        { name: "Bonuses", href: "/hub/bonuses" },
      ],
    },
    { name: "Tips", href: "/tips" },
    { name: "Pick’ems", href: "/pickems" },
  ];

  const social = [
    {
      name: "Twitter",
      href: "https://x.com",
      icon: (props: any) => <TwitterIcon />,
    },
    {
      name: "YouTube",
      href: "https://www.youtube.com",
      icon: (props: any) => <YoutubeIcon />,
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com",
      icon: (props: any) => <InstagramIcon />,
    },
    {
      name: "TikTok",
      href: "https://www.tiktok.com",
      icon: (props: any) => <TikTokIcon />,
    },
  ];

  return (
    <header className="min-w-screen text-white inset-x-0 sticky top-0 z-50 flex flex-col bg-transparent">
      {/* header top */}
      <div
        key={"header-top"}
        className="flex flex-row w-full bg-dark-gray h-[4.5rem] z-10"
      >
        <div className="p-4 md:p-0 mx-auto flex w-full max-w-[73.75rem] items-center justify-between">
          <GoCoreLogo className="md:block mr-[2.875rem]" />
          <nav
            key={"navigation"}
            className="hidden md:flex md:gap-x-[0.703rem] md:text-sm md:font-semibold flex-1"
          >
            {navigation.map((item, itemIdx) => (
              <Fragment key={nanoid()}>
                {itemIdx === 3 ? (
                  <div
                    key={"dropdown-button"}
                    className="flex flex-row md:gap-x-[0.703rem]"
                  >
                    <Popover className="relative">
                      {({ open }) => (
                        <>
                          <PopoverButton
                            className={clsx(
                              "px-[0.906rem] py-[0.75rem] border-solid border border-dark-gray rounded-lg flex",
                              pathname.includes("/hub")
                                ? "font-bold bg-light-blue text-dark-gray"
                                : "hover:bg-light-blue-o10 hover:border-solid hover:border hover:border-light-blue-o50 font-normal"
                            )}
                          >
                            <span>Betting Hub</span>
                            {open ? (
                              <ChevronUpIcon
                                className="ml-2 h-5 w-5 transition duration-150 ease-in-out group-hover:text-orange-300/80`
"
                              />
                            ) : (
                              <ChevronDownIcon
                                className="ml-2 h-5 w-5 transition duration-150 ease-in-out group-hover:text-orange-300/80`
"
                              />
                            )}
                          </PopoverButton>
                          <PopoverPanel
                            transition
                            className="absolute left-1/2 z-10 mt-5 flex  -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                          >
                            <div className=" flex-auto overflow-hidden rounded-3xl bg-dark-gray text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                              <div className="p-4">
                                {solutions.map((item) => (
                                  <div
                                    key={item.name}
                                    className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50"
                                  >
                                    <Link
                                      href={item.href}
                                      className="font-medium text-white "
                                    >
                                      {item.name}
                                      <span className="absolute inset-0" />
                                    </Link>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </PopoverPanel>
                        </>
                      )}
                    </Popover>
                    <Link
                      className={clsx(
                        "px-[0.906rem] py-[0.75rem] border-solid border border-dark-gray rounded-lg ",
                        pathname.includes(item.href)
                          ? "font-bold bg-light-blue border-light-blue text-dark-gray"
                          : "hover:bg-light-blue-o10 hover:border hover:border-light-blue-o50 font-normal"
                      )}
                      key={itemIdx}
                      href={item.href}
                    >
                      {item.name}
                    </Link>
                  </div>
                ) : (
                  <Link
                    className={clsx(
                      "px-[0.906rem] py-[0.75rem] border-solid border border-dark-gray rounded-lg ",
                      pathname.includes(item.href)
                        ? "font-bold bg-light-blue border-light-blue text-dark-gray"
                        : "hover:bg-light-blue-o10 hover:border hover:border-light-blue-o50 font-normal"
                    )}
                    key={itemIdx}
                    href={item.href}
                  >
                    {item.name}
                  </Link>
                )}
              </Fragment>
            ))}
          </nav>

          {
            isPlayerLoading ? <Loader /> : player ? (
              <Link className="w-full border border-solid rounded-lg font-extrabold text-sm text-center min-w-[220px] py-3 max-h-[58px] bg-light-blue border-light-blue text-black max-w-8" href="/profile">
                Profile
              </Link>
            ) : (
              <>
                <span className="hidden md:block text-sm mr-4 cursor-pointer" onClick={toggleLoginDialog}>
                  Login
                </span>
                <Button
                  variant="primary"
                  onClick={toggleRegisterDialog}
                  className="w-36"
                >
                  Register
                </Button>
              </>
            )
          }




          <div className="md:hidden flex flex-1 items-center justify-end gap-x-[0.75rem] ml-4">
            <button
              type="button"
              className="-m-3 p-3 md:hidden"
              onClick={toggleMobileMenu}
            >
              <span className="sr-only">Open main menu</span>
              {mobileMenuOpen ? <Close /> : <Menu />}
            </button>
          </div>
        </div>
      </div>
      {/* header bottom */}
      <div
        key={"header-bottom"}
        className="flex flex-row w-full bg-medium-gray "
      >
        <div className="mx-auto flex w-full max-w-[73.75rem] items-center  max-md:justify-start max-md:px-3 space-x-4 py-[0.5rem] justify-items-center	">
          {games.map((item, index) => (
            <Link
              key={nanoid()}
              href={item.href}
              onClick={() => setValue(item.name as GameFilter)}
              className={clsx(
                "flex flex-row gap-2 rounded-lg px-5 py-1 text-sm text-white font-bold content-center items-center",
                value === item.name
                  ? "bg-[#192934]"
                  : "bg-medium-gray hover:bg-light-gray"
              )}
            >
              {item.icon ? item.icon : <></>}
              {item.label}
            </Link>
          ))}
        </div>
      </div>

      <Sidebar
        isOpen={mobileMenuOpen}
        className="md:hidden w-full h-[100%]"
      >
        <div className="flex flex-col flex-1 justify-between h-full">
          <div className="mt-20 space-y-2">
            {mobileNavigation.map((item) => (
              <Fragment key={nanoid()}>
                {item?.subLinks ? (
                  <div key={nanoid()} className={"flex flex-col"}>
                    <p className="leading-5 px-3 text-base font-medium text-white rounded-lg py-[0.906rem]">
                      {item.name}
                    </p>
                    <div className="mt-2 space-y-2 ml-5">
                      {item.subLinks.map((link) => (
                        <Link
                          key={link.name}
                          href={link.href}
                          onClick={toggleMobileMenu}
                          className={clsx(
                            "block rounded-lg leading-5	px-3 text-base font-medium text-white py-[0.906rem]",
                            pathname == link.href &&
                            "font-bold bg-light-blue-o10 border border-solid border-light-blue-o50 text-dark-gray "
                          )}
                        >
                          {link.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                ) : (
                  <Link
                    key={item.name}
                    href={item.href}
                    onClick={toggleMobileMenu}
                    className={clsx(
                      "block rounded-lg leading-5	px-3 text-base font-medium text-white py-[0.906rem]",
                      pathname.includes(item.href) &&
                      "font-bold bg-light-blue-o10 border border-solid border-light-blue-o50 text-dark-gray "
                    )}
                  >
                    {item.name}
                  </Link>
                )}
              </Fragment>
            ))}
          </div>
          <div className="flex flex-col gap-y-6">
            <div className="flex flex-row gap-x-4 px-3">
              {social.map((item) => (
                <Link
                  target="_blank"
                  key={nanoid()}
                  href={item.href}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </Link>
              ))}
            </div>
          </div>
        </div>
      </Sidebar>



      <Transition appear show={loginDialogOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={toggleLoginDialog}>
          <DialogBackdrop className="fixed inset-0 bg-black bg-opacity-50" />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-light-gray px-4 py-5 md:p-12  text-left align-middle shadow-xl transition-all">
                  <div className="flex items-center justify-between mb-5">
                    <GoCoreLogo />
                    <div className="flex flex-row gap-2">
                      <button
                        type="button"
                        onClick={toggleLoginDialog}
                      >
                        <XMarkIcon className="h-9 w-9 text-white" />
                      </button>
                    </div>
                  </div>
                  <LoginForm onLogin={toggleLoginDialog} />
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={registerDialogOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={toggleRegisterDialog}>
          <DialogBackdrop className="fixed inset-0 bg-black bg-opacity-50" />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-light-gray px-4 py-5 md:p-12 text-left align-middle shadow-xl transition-all">
                  <div className="flex items-center justify-between mb-5">
                    <GoCoreLogo />
                    <div className="flex flex-row gap-2">
                      <button
                        type="button"
                        onClick={toggleRegisterDialog}
                      >
                        <XMarkIcon className="h-9 w-9 text-white" />
                      </button>
                    </div>
                  </div>
                  <RegisterForm onRegister={toggleRegisterDialog} />
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </header >
  );
};

export default Header;
