export interface Player {
  email: string;
  firstName: string;
  lastName: string;
  // city: string;
  // gender: string;
  // birthDate: string;
  // nationality: string;
  // profilePhoto: string;
  password: string;
  passwordAgain: string;
}

const registerPlayer = async (newPlayer: Player) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/api/Players`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newPlayer),
    }
  );

  if (!response.ok) {
    throw new Error("Failed to register");
  }

  const result = await response.json();
  return result;
};

export default registerPlayer;
