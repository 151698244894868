import useSWR, { mutate, useSWRConfig } from "swr";
import { swrFetcherGetConfig } from "@/configs/swrConfig";
import Cookies from "js-cookie";
import { Player } from "@/api/auth/registerPlayer";

interface LoginCredentials {
  email: string;
  credentials: string;
}

const usePlayer = () => {
  const fetcher = async (url: string, data: any) => {
    const token = Cookies.get("access_token");

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token || data.access_token}`,
      },
      method: "GET",
    });

    if (!response.ok) {
      throw new Error("Failed to fetch player data");
    }

    return await response.json();
  };

  const login = async ({ email, credentials }: LoginCredentials) => {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/connect/token`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          client_id: "jwt.web-api",
          client_secret: "secret",
          grant_type: "credentials",
          email,
          credentials,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to login");
    }

    const data = await response.json();
    Cookies.set("access_token", data.access_token);

    mutate(`${process.env.NEXT_PUBLIC_API_URL}/api/Players`, data);
  };

  const logout = () => {
    Cookies.remove("access_token");
    mutate(`${process.env.NEXT_PUBLIC_API_URL}/api/Players`, null);
  };

  const updatePlayerInfo = async (data: Player) => {
    const token = Cookies.get("access_token");

    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/api/Players`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update player info");
    }

    return await response.json();
  };

  const url = `${process.env.NEXT_PUBLIC_API_URL}/api/Players`;
  const { data, isLoading } = useSWR(url, fetcher, swrFetcherGetConfig);

  return { data, isLoading, login, logout, updatePlayerInfo };
};

export default usePlayer;
