
'use client'

import { UserIcon, LockClosedIcon } from "@heroicons/react/24/solid";
import Button from "@/components/button";
import { Formik, Form } from 'formik';
import FormikInput from "@/components/input/FormikInput";
import { Bounce, toast } from "react-toastify";
import { useRouter } from "next/navigation";
import usePlayer from "@/hooks/usePlayer";

interface LoginFormProps {
  onLogin?: () => void;
}

const LoginForm = ({ onLogin = () => { } }: LoginFormProps) => {
  const router = useRouter();
  const { login } = usePlayer()

  return <Formik initialValues={{
    email: '',
    credentials: '',
  }}
    onSubmit={async (values, actions) => {
      try {
        await login(values);
        toast.info("Login successful", {
          position: "top-right",
          autoClose: Infinity,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
        });
        router.push('/profile');
        onLogin();
      } catch (error) {
        actions.setErrors({ email: "Email or password incorrect", credentials: "Email or password incorrect" })
      }
    }}>
    {() => (
      <Form>
        <div className="flex flex-col gap-2">

          <div className="self-center flex flex-col gap-5 w-full max-w-[480px]">
            <h2 className="text-2xl font-bold">Welcome back, please sign in</h2>
          </div>
          <div className="self-center flex flex-col gap-5 w-full max-w-[480px]">
            <div className="flex flex-col gap-2 w-full">
              <span className="text-base font-normal text-blue-gray">
                Login info
              </span>

              <FormikInput
                type="email"
                name="email"
                startIcon={<UserIcon />}
                placeholder="Your email "
              />

              <FormikInput
                type="password"
                name="credentials"
                startIcon={<LockClosedIcon />}
                placeholder="Your password"
              />


            </div>



            <Button variant="primary" type="submit">Sign In</Button>
          </div>
        </div>
      </Form>
    )}


  </Formik>

}

export default LoginForm;